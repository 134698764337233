<template>
  <div style="border-radius: 5px;">
    <div class="detal">
      <!-- 发票信息页面部分 -->
      <div class="invoiceInformationBox">
        <div class="invoiceTitle">发票信息</div>
        <div class="invoiceInformationContent">
          <div class="invoiceInformationType">
            <span>发票信息，有效！</span>
            <!-- <span style="cursor: pointer;color:#0099FF"  @click="showPartFn()">修改发票信息</span> -->
            <el-button
              style="position:absolute;top: 50%; transform: translateY(-50%);"
              @click="showPartFn()"
              type="primary"
              size="small"
            >修改发票信息</el-button>
          </div>
          <div class="invoiceInformationMain">
            <div class="left">
              <span v-if="InvoiceData.receipt_title && InvoiceData.receipt_title != ''">发票抬头：</span>
              <span v-if="InvoiceData.receipt_type && InvoiceData.receipt_type != ''">发票类型：</span>
              <span v-if="InvoiceData.tax_no && InvoiceData.tax_no != ''">发票税号：</span>

              <span v-if="InvoiceData.bankName && InvoiceData.bankName!= ''">开户银行：</span>
              <span v-if="InvoiceData.bank_account && InvoiceData.bank_account != ''">银行账户：</span>
              <span v-if="InvoiceData.reg_addr && InvoiceData.reg_addr != ''">注册地址：</span>
              <span v-if="InvoiceData.reg_tel && InvoiceData.reg_tel != ''">注册电话：</span>
            </div>
            <div class="right">
              <span
                v-if="InvoiceData.receipt_title && InvoiceData.receipt_title != ''"
              >{{InvoiceData.receipt_title}}</span>
              <span
                v-if="InvoiceData.receipt_type && InvoiceData.receipt_type != ''"
              >{{InvoiceData.receipt_type === 'ELECTRO'? '电子普通发票':'增值税专用发票'}}</span>
              <span v-if="InvoiceData.tax_no && InvoiceData.tax_no != ''">{{InvoiceData.tax_no}}</span>
              <span
                v-if="InvoiceData.bankName && InvoiceData.bankName!= ''"
              >{{InvoiceData.bankName}}</span>
              <span
                v-if="InvoiceData.bank_account && InvoiceData.bank_account != ''"
              >{{InvoiceData.bank_account}}</span>
              <span
                v-if="InvoiceData.reg_addr && InvoiceData.reg_addr != ''"
              >{{InvoiceData.reg_addr}}</span>
              <span v-if="InvoiceData.reg_tel && InvoiceData.reg_tel != ''">{{InvoiceData.reg_tel}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as API_goods from "@/api/goods";

export default {
  name: "administer",
  data() {
    return {
      InvoiceData: {}
    };
  },
  mounted() {
    API_goods.getInvoiceTitleDetail().then(res => {
      this.InvoiceData = res;
    });
  },
  methods: {
    showPartFn() {
      this.$router.push({ path: "/finance/Invoicemanger/Invoicemangerdetail" });
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ div.toolbar {
  height: auto;
  border: none;
}
/deep/ div.el-table--border {
  border: none !important;
}
/deep/ div.el-table::before {
  height: 0;
}
.stepbox {
  box-sizing: border-box;
  padding: 0px 40px;
  min-width: 900px;
  width: 100%;
  height: 90px;
  background: #fff;
  border-radius: 5px 5px 0 0;
  > div {
    display: inline-block;
    width: 380px;
    height: 48px;
    text-align: center;
    p {
      width: 340px;
      height: 48px;
      background: #00a0e9;
      line-height: 48px;
      margin: 0;
      text-align: center;
      color: #ffffff;
      float: left;
    }
    div {
      float: left;
      width: 0;
      height: 0;
      border-left: 14px solid #00a0e9;
      border-top: 24px solid #ffffff;
      border-bottom: 24px solid #ffffff;
    }
    .threeths {
      border-left: 14px solid #ffffff;
      border-top: 24px solid #00a0e9;
      border-bottom: 24px solid #00a0e9;
    }
  }
}
.seletbox {
  width: 100%;
  min-width: 900px;
  height: 250px;
  background: #fff;
  padding-top: 30px;
  box-sizing: border-box;
  p {
    line-height: 25px;
    margin: 0;
  }
  > div {
    display: inline-block;
    margin-top: 20px;
  }
  .hover {
    > span {
      display: inline-block;
      margin-right: 10px;
      cursor: pointer;
    }
    span:hover {
      color: #00a0e9;
    }
  }
}
.databox {
  width: 100%;
  margin-top: 30px;
  box-sizing: border-box;
  > p {
    padding: 0;
    position: relative;
    padding-left: 18px;
    font-size: 16px;
    line-height: 22px;
    color: #393c41;
  }
  > p:before {
    content: " ";
    display: block;
    width: 6px;
    height: 22px;
    background: #1a43a9;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 3px;
  }
  > div {
    background: #ffffff;
    padding: 20px 0;
    border-radius: 5px;
    width: 100%;
    div {
      display: inline-block;
      width: 40%;
      p {
        margin: 0;
        width: 100%;
        padding-left: 5%;
        text-align: left;
        line-height: 24px;
        border-right: 1px solid #dcdfe6;
      }
    }
    .goright {
      display: inline-block;
      padding-left: 4%;
      width: 19%;
    }
  }
  .yikai {
    color: #e3405b;
    font-size: 30px;
    padding-top: 10px;
    font-weight: bold;
  }
  .daikai {
    color: #5dbe2d;
    font-size: 30px;
    padding-top: 10px;
    font-weight: bold;
  }
  .noueb {
    border: none;
    margin-left: -7%;
  }
}

.invoiceTitle {
  font-size: 18px;
  font-weight: 400;
  color: #666;
}
.invoiceInformationBox {
  .invoiceInformationContent {
    margin-top: 10px;
    // margin-left:18%;
    // border:1px solid #000;
    .invoiceInformationType {
      margin-left: 75px;
      position: relative;
      span {
        font-size: 14px;
        color: #1abc9c;
      }
    }
    .invoiceInformationMain {
      display: flex;
      span {
        display: block;
        line-height: 40px;
        font-size: 16px;
      }
      .left {
        width: 154px;
        margin-right: 15px;
        span {
          text-align: right;
          font-weight: bold;
        }
      }
      .right {
        span {
          text-align: left;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.detal {
  line-height: 35px;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
}
.span {
  display: inline-block;
  width: 150px;
  text-align: right;
}
.detal h3 {
  padding: 0;
  position: relative;
  padding-left: 18px;
  font-size: 16px;
  line-height: 22px;
  color: #393c41;
  font-weight: normal;
}
.detal h3:before {
  content: " ";
  display: block;
  width: 6px;
  height: 22px;
  background: #1a43a9;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 3px;
}
.toolbar-search {
  width: 100% !important;
}
</style>
